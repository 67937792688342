import { createRouter, createWebHashHistory } from 'vue-router'
import type { RouteRecordRaw } from 'vue-router'
import type { App } from 'vue'
// import { Layout, getParentLayout } from '@/utils/routerHelper'
import { Layout } from '@/utils/routerHelper'
import { useI18n } from '@/hooks/web/useI18n'

const { t } = useI18n()

export const constantRouterMap: AppRouteRecordRaw[] = [
  {
    path: '/',
    component: Layout,
    redirect: '/lotterys',
    name: 'Root',
    meta: {
      hidden: true
    }
  },
  {
    path: '/update_pwd',
    name: 'Update_pwd',
    component: () => import('@/views/UpdatePwd/Index.vue'), // Fix the file name casing
    meta: {
      title: ''
    }
  },
  {
    path: '/redirect',
    component: Layout,
    name: 'Redirect',
    children: [
      {
        path: '/redirect/:path(.*)',
        name: 'Redirect',
        component: () => import('@/views/Redirect/Redirect.vue'),
        meta: {}
      }
    ],
    meta: {
      hidden: true,
      noTagsView: true
    }
  },
  {
    path: '/login',
    component: () => import('@/views/Login/Login.vue'),
    name: 'Login',
    meta: {
      hidden: true,
      title: t('router.login'),
      noTagsView: true
    }
  },
  {
    path: '/h5_download',
    component: () => import('@/views/h5/index.vue'),
    name: 'H5_download',
    meta: {
      hidden: true,
      title: '安装包下载',
      noTagsView: true
    }
  },
  {
    path: '/404',
    component: () => import('@/views/Error/404.vue'),
    name: 'NoFind',
    meta: {
      hidden: true,
      title: '404',
      noTagsView: true
    }
  }
]

export const asyncRouterMap: AppRouteRecordRaw[] = [
  {
    path: '/agreement',
    component: () => import('@/views/Agreement/index.vue'),
    name: 'Agreement',
    meta: {
      // hidden: true,
      noTagsView: true
    }
  },
  {
    path: '/lotterys',
    component: Layout,
    redirect: '/lotterys/index',
    name: 'Lotterys',
    meta: {
      // title: t('router.level'),
      title: '',
      icon: 'carbon:skill-level-advanced'
    },
    children: [
      {
        path: 'index',
        name: 'LotterysIndex',
        component: () => import('@/views/Lotterys/Index.vue'), // Fix the file name casing
        meta: {
          title: ''
        }
      }
    ]
  },
  {
    path: '/member',
    component: Layout,
    redirect: '/member/bets',
    name: 'Member',
    meta: {
      title: '',
      icon: 'carbon:skill-level-advanced'
    },
    children: [
      {
        path: 'bets',
        name: 'Bets',
        component: () => import('@/views/Bets/Index.vue'), // Fix the file name casing
        meta: {
          title: ''
        }
      },
      {
        path: 'history',
        name: 'History',
        component: () => import('@/views/History/Index.vue'), // Fix the file name casing
        meta: {
          title: ''
        }
      },
      {
        path: 'dayReport',
        name: 'DayReport',
        component: () => import('@/views/History/DayReport.vue'), // Fix the file name casing
        meta: {
          title: ''
        }
      },
      {
        path: 'gameReport',
        name: 'GameReport',
        component: () => import('@/views/History/GameReport.vue'), // Fix the file name casing
        meta: {
          title: ''
        }
      },
      {
        path: 'dresult',
        name: 'Dresult',
        component: () => import('@/views/Dresult/Index.vue'), // Fix the file name casing
        meta: {
          title: ''
        }
      },
      {
        path: 'info',
        name: 'Info',
        component: () => import('@/views/Info/Index.vue'), // Fix the file name casing
        meta: {
          title: ''
        }
      },
      {
        path: 'update_pwd',
        name: 'UpdatePwd',
        component: () => import('@/views/UpdatePwd/Index.vue'), // Fix the file name casing
        meta: {
          title: ''
        }
      },
      {
        path: 'rule',
        name: 'Rule',
        component: () => import('@/views/Rule/Index.vue'), // Fix the file name casing
        meta: {
          title: ''
        }
      },

      {
        path: 'complex_bet',
        name: 'ComplexBet',
        component: () => import('@/views/ComplexBet/Index.vue'), // Fix the file name casing
        meta: {
          title: ''
        },
        children: [
          {
            path: 'add',
            name: 'ComplexBetAdd',
            component: () => import('@/views/ComplexBet/Add.vue'), // Fix the file name casing
            meta: {
              title: ''
            }
          }
        ]
      },
      {
        path: 'factoryauthenication',
        name: 'Factoryauthenication',
        component: () => import('@/views/Factoryauthenication/index.vue'), // Fix the file name casing
        meta: {
          title: '二次验证'
        }
      }
    ]
  }

  // {
  //   path: '/level',
  //   component: Layout,
  //   redirect: '/level/menu1/menu1-1/menu1-1-1',
  //   name: 'Level',
  //   meta: {
  //     title: t('router.level'),
  //     icon: 'carbon:skill-level-advanced'
  //   },
  //   children: [
  //     {
  //       path: 'menu1',
  //       name: 'Menu1',
  //       component: getParentLayout(),
  //       redirect: '/level/menu1/menu1-1/menu1-1-1',
  //       meta: {
  //         title: t('router.menu1')
  //       },
  //       children: [
  //         {
  //           path: 'menu1-1',
  //           name: 'Menu11',
  //           component: getParentLayout(),
  //           redirect: '/level/menu1/menu1-1/menu1-1-1',
  //           meta: {
  //             title: t('router.menu11'),
  //             alwaysShow: true
  //           },
  //           children: [
  //             {
  //               path: 'menu1-1-1',
  //               name: 'Menu111',
  //               component: () => import('@/views/Level/Menu111.vue'),
  //               meta: {
  //                 title: t('router.menu111')
  //               }
  //             }
  //           ]
  //         },
  //         {
  //           path: 'menu1-2',
  //           name: 'Menu12',
  //           component: () => import('@/views/Level/Menu12.vue'),
  //           meta: {
  //             title: t('router.menu12')
  //           }
  //         },
  //         {
  //           path: 'menu1-3',
  //           name: 'Menu13',
  //           component: () => import('@/views/Level/Menu12.vue'),
  //           meta: {
  //             title: t('router.menu12')
  //           }
  //         }
  //       ]
  //     },
  //     {
  //       path: 'menu2',
  //       name: 'Menu2',
  //       component: () => import('@/views/Level/Menu2.vue'),
  //       meta: {
  //         title: 'Pk10jsc'
  //       }
  //     }
  //   ]
  // }
]

const router = createRouter({
  history: createWebHashHistory(),
  strict: true,
  routes: constantRouterMap as RouteRecordRaw[],
  scrollBehavior: () => ({ left: 0, top: 0 })
})

export const resetRouter = (): void => {
  const resetWhiteNameList = ['Redirect', 'Login', 'NoFind', 'Root']
  router.getRoutes().forEach((route) => {
    const { name } = route
    if (name && !resetWhiteNameList.includes(name as string)) {
      router.hasRoute(name) && router.removeRoute(name)
    }
  })
}

export const setupRouter = (app: App<Element>) => {
  app.use(router)
}

export default router
