import request from '@/axios'

// import type { UserType } from './types'
// import { getUserInfo, saveUserInfo } from '@/axios/apiBase'

interface RoleParams {
  roleName: string
}

export const loginApi = (data: UserLoginForm): Promise<IResponse<LoginInfo<LoginUserInfo>>> => {
  return request.post({ url: '/member_sign_in', data, auth: false })
}
export const getComFlag = (): Promise<IResponse<{ cid: string }>> => {
  return request.post({ url: '/com_flag' })
}
export const getBaseInfo = (comFlag: string): Promise<IResponse<any>> => {
  const params = {}

  return request.get({
    url:
      window.location.protocol +
      '//' +
      window.location.host +
      import.meta.env.VITE_BASE_PATH +
      `share/${comFlag}/baseInfo?` +
      new Date().getTime(),
    params,
    auth: false
  })
}

export const registerApi = (data: UserLoginForm): Promise<IResponse> => {
  return request.post({ url: '/member_self_sign_up', data })
}

export const loginOutApi = (): Promise<IResponse> => {
  return request.post({ url: '/sign_out' })
}

export const getAdminRoleApi = (
  params: RoleParams
): Promise<IResponse<AppCustomRouteRecordRaw[]>> => {
  return request.get({ url: '/mock/role/list', params })
}

export const getTestRoleApi = (params: RoleParams): Promise<IResponse<string[]>> => {
  return request.get({ url: '/mock/role/list2', params })
}
