<script setup lang="ts">
import { computed } from 'vue'
import { useAppStore } from '@/store/modules/app'
import { ConfigGlobal } from '@/components/ConfigGlobal'
import { useDesign } from '@/hooks/web/useDesign'

const { getPrefixCls } = useDesign()

const prefixCls = getPrefixCls('app')

const appStore = useAppStore()

const currentSize = computed(() => appStore.getCurrentSize)

const greyMode = computed(() => appStore.getGreyMode)

appStore.initTheme()
</script>

<template>
  <ConfigGlobal :size="currentSize">
    <RouterView :class="greyMode ? `${prefixCls}-grey-mode` : ''" />
  </ConfigGlobal>
</template>

<style lang="less">
@prefix-cls: ~'@{namespace}-app';

.size {
  width: 100%;
  height: 100%;
}

html,
body {
  padding: 0 !important;
  margin: 0;
  overflow: hidden;
  .size;

  #app {
    .size;
  }
}

.@{prefix-cls}-grey-mode {
  filter: grayscale(100%);
}

.logo {
  position: absolute;
  top: 5px;
  left: 15px;
  width: 135px;

  span {
    display: block;
    width: 180px;
    height: 50px;
    font-size: 30px;
    font-weight: bolder;
    line-height: 50px;
    letter-spacing: 5px;
    color: #fff;
    text-align: center;
  }
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}
.el-message-box__btns {
  justify-content: flex-start !important;
  flex-direction: row-reverse !important;
  button:nth-child(1) {
    margin-left: 10px !important;
  }
}
.el-date-table {
  th,
  td {
    border: unset;
  }
}
.popup {
  border-radius: unset !important;
}
.el-dialog.notice {
  background: var(--app-notice-dialog-bg-color);
  .el-dialog__header {
    padding-top: 10px;
    margin-right: unset;
  }
  .el-dialog__body {
    padding-top: 0;
  }
}
</style>
